* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
}

body {
  background: #03254C;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

a svg {
  fill: white !important;
}